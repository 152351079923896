<script>
import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
  },
})
export default class EventForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];

  get platformLanguagesOptions() {
    return this.platformLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
  }

  get selectedLanguages() {
    const modelLanguages = this.model?.languageIds ?? [];
    return this.platformLanguages.filter((l) => {
      return modelLanguages.includes(l.id);
    });
  }

  get helpLink() {
    return this.translations.pageHelp.event;
  }

  get selectedLanguagesOptions() {
    const selectedLanguages = this.selectedLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
    return selectedLanguages;
  }

  get elements() {
    return [
      {
        id: "languageIds",
        label: this.translations.labels.languages,
        type: "select",
        items: this.platformLanguagesOptions,
        multiple: true,
        required: true,
      },
      {
        id: "defaultLanguageId",
        label: this.translations.labels.languages_default,
        type: "select",
        items: this.selectedLanguagesOptions,
        required: true,
      },
      {
        id: "code",
        label: this.translations.labels.event_form_code,
        type: "text",
        required: true,
        rules: "min:4|forbiddenCharacters",
      },
      {
        id: "publishDate",
        label: this.translations.labels.event_form_publishDate,
        type: "datetime",
        rules: "maxDate:@sibling.startDate",
      },
      {
        id: "startDate",
        label: this.translations.labels.event_form_startDate,
        type: "datetime",
        required: true,
      },
      {
        id: "endDate",
        label: this.translations.labels.event_form_endDate,
        type: "datetime",
        required: true,
        rules: "gtDate:@sibling.startDate",
      },
      {
        id: "logoUri",
        label: this.translations.labels.event_form_logoImage,
        type: "media",
        mediaType: "image",
        required: true,
        hint: this.translations.labels
              .event_form_hint_logoSize,
        persistentHint:true,
      },
      {
        id: "mouseIconUri",
        label: this.translations.labels.event_form_mouseIcon,
        type: "media",
        mediaType: "image",
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.form_localizations,
        languages: this.selectedLanguages,
        resetColumns: true,
        elements: [
          {
            id: "name",
            label: this.translations.labels.event_form_name,
            type: "text",
            required: true,
          },
          {
            id: "imageUri",
            label: this.translations.labels.event_form_registrationImage,
            type: "media",
            mediaType: "background",
            required: true,
          },
          {
            id: "taglineUri",
            label: this.translations.labels.event_form_taglineImage,
            type: "media",
            mediaType: "image",
            hint: this.translations.labels
              .event_form_hint_taglineSize,
            persistentHint:true,
          },
          {
            id: "startingText",
            label: this.translations.labels.event_form_startingText,
            type: "wysiwyg",
            resetColumns: true,
          },
          {
            id: "progressText",
            label: this.translations.labels.event_form_progressText,
            type: "wysiwyg",
            resetColumns: true,
          },
          {
            id: "endText",
            label: this.translations.labels.event_form_endText,
            type: "wysiwyg",
            resetColumns: true,
          },
          {
            id: "notInProgressUserLoggedText",
            label:
              this.translations.labels.event_form_notInProgressUserLoggedText,
            hint: this.translations.labels
              .event_form_hint_notInProgressUserLoggedText,
            type: "wysiwyg",
            resetColumns: true,
          },
          {
            id: "landingFooterText",
            label:
              this.translations.labels.event_form_landingFooterText,
            hint: this.translations.labels
              .event_form_hint_notInProgressUserLoggedText,
            type: "wysiwyg",
            resetColumns: true,
          },
        ],
      },
    ];
  }

  async created() {
    this.platformLanguages = await this.languageService.list();
  }
}
</script>
