<script>
import EventForm from "@/views/menu/event/eventForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    eventService: "eventService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  },
})
export default class EventNew extends mixins(EventForm) {
  formId = "new-event-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.eventService.create(this.model);
      this.$router.push("/event/list");
      toastService.success(this.translations.success.event_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.events_new;
  }

  created() {
    this.model = {
      languageIds: null,
      defaultLanguageId: null,
      code: null,
      startDate: null,
      publishDate: null,
      logoUri: null,
      mouseIconUri: null,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
